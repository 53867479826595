import React from 'react';

import { Hero } from '../components/InnerPages/Hero';
import { InnerPageLayout } from '../components/InnerPages/Layout';

import style from '../styles/pages/about.scss';

import { graphql } from 'gatsby';

interface IAboutProps {
  data: {
    ghostPage: {
      codeinjection_styles: any;
      title: string;
      html: string;
      feature_image?: string;
    };
  };
  location: any;
}

const About = ({ data, location }: IAboutProps) => {
  const page = data.ghostPage;

  return (
    <InnerPageLayout articleHTML={page.html} location={location}>
      <Hero
        className={style.hero}
        title={page.title}
        description="This blog is about getting personal and thinking about money in a goal-oriented way. We share stories about how to achieve financial independence and gain freedom and control to live the lifestyle you design."
      />
    </InnerPageLayout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    ghostPage(slug: { eq: "about" }) {
      html
      title
    }
  }
`;
